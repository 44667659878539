import { IGame } from '../../../Services/Api/IReportApiService';

export const GameRowItem = ({ game, hideType = false }: { game: IGame; hideType?: boolean }) => {
    return (
        <div className="game-row-item">
            {game.image ? <img src={game.image} alt={game.name} /> : <></>}
            <div className={`info ${hideType ? 'justify-content-center' : ''}`}>
                <span className="name">{game.name}</span>
                {!hideType && <span className="type">{game.type}</span>}
            </div>
        </div>
    );
};
