import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Search } from '../../../Components/Common/Search';
import { Pagination } from '../../../Components/Common/Pagination';
import { IFetchAllProvidersApiRequestBody, IProvider } from '../../../slices/types';
import { allProvidersDataSelector } from '../../../selectors/providersSelectors';
import { fetchAllProviders } from '../../../slices/providers/thunk';
import { MergeProvidersDialog } from './MergeProvidersDialog';
import { PageTitle } from '../../../Components/Common/PageTitle';

export const AllProviders = () => {
    const dispatch: any = useDispatch();
    const { data, totalPages } = useSelector(allProvidersDataSelector);
    const [isMergeProvidersDialogOpen, setIsMergeProvidersDialogOpen] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState<IProvider | undefined>(undefined);

    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const changePage = (page: number) => {
        setSelectedPage(page);
        fetchData({ page, q: searchTerm });
    };

    const search = (q: string) => {
        setSearchTerm(q);
        setSelectedPage(1);
        fetchData({ page: 1, q });
    };

    const fetchData = (params: IFetchAllProvidersApiRequestBody) => {
        dispatch(fetchAllProviders(params));
    };

    useEffect(() => {
        fetchData({ page: 1, q: searchTerm });
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title="Providers" />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Search search={search} placeholder="Search By Name..." />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                <div className="table-responsive">
                                                    <table className="table table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <td scope="col" className="min-w">
                                                                    ID
                                                                </td>
                                                                <td scope="col">Name</td>
                                                                <td scope="col" className="min-w">
                                                                    Game Count
                                                                </td>
                                                                <td scope="col" className="text-end">
                                                                    Action
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((provider: any, index: number) => (
                                                                <tr key={`provider-${provider.id}`}>
                                                                    <td>{(selectedPage - 1) * 10 + index + 1}</td>
                                                                    <td>{provider.name}</td>
                                                                    <td className="text-center">
                                                                        <h4>
                                                                            <Badge color="primary">
                                                                                {provider.game_count}
                                                                            </Badge>
                                                                        </h4>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <Button
                                                                            onClick={() => {
                                                                                setSelectedProvider(provider);
                                                                                setIsMergeProvidersDialogOpen(true);
                                                                            }}
                                                                            color="primary"
                                                                        >
                                                                            Merge
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination currentPage={selectedPage} totalPages={totalPages} changePage={changePage} />
                </Container>
            </div>
            <MergeProvidersDialog
                isOpen={isMergeProvidersDialogOpen}
                onClose={(refresh = false) => {
                    setIsMergeProvidersDialogOpen(false);
                    setSelectedProvider(undefined);

                    if (refresh) {
                        fetchData({ page: selectedPage, q: searchTerm });
                    }
                }}
                childProvider={selectedProvider}
            />
            <ToastContainer />
        </>
    );
};
