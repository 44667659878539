import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGamesOnOperator } from '../../../slices/reports/thunk';
import { gamesOnOperatorDataSelector } from '../../../selectors/reportsSelectors';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { resetGamesOnSectionsAction } from '../../../slices/reports/reducer';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { userRelatedDataCountsSelector } from '../../../selectors/accountSelectors';
import { InfoCard } from '../../../Components/Common/InfoCard';
import { getUniqueGamesCountFromSection } from '../../../utils/SectionUtils';
import { GameRowItem } from '../../../Components/Common/Table/GameRowItem';
import { NoDataTableRow } from '../../../Components/Common/Table/NoDataTableRow';
import { OperatorMainFilter } from '../../../Components/Common/Filter/OperatorMainFilter';
import { buildTree } from '../../../utils/bulitSectionTree';
import { SectionTree } from './SectionTree';

export const GamesOnOperator = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const relatedDataCounts = useSelector(userRelatedDataCountsSelector);
    const { sections, isInProgress } = useSelector(gamesOnOperatorDataSelector);

    const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(0);
    const [selectedProviderId] = useState(user.providerId);
    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);

    useEffect(() => {
        if (selectedOperatorId) {
            dispatch(fetchGamesOnOperator(selectedProviderId, selectedGameId, selectedOperatorId));
        } else {
            dispatch(resetGamesOnSectionsAction());
        }

        setSelectedSectionIndex(0);
    }, [selectedProviderId, selectedGameId, selectedOperatorId, dispatch]);

    const totalFoundGames = getUniqueGamesCountFromSection(sections);
    const tree = buildTree(sections);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'Operators Overview'} />
                                <OperatorMainFilter isInProgress={isInProgress} onChange={setSelectedOperatorId} />
                                <Row>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            title="Games Found"
                                            iconType="game"
                                            amount={totalFoundGames}
                                            subAmount={relatedDataCounts.games}
                                            amountColor="warning"
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedGame
                                                            setValue={setSelectedGameId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {isInProgress ? (
                                                    <></>
                                                ) : (
                                                    <div className="table-with-manu">
                                                        <div className="menu">
                                                            <div className="title">Operator's structure</div>
                                                            <div className="description">
                                                                <SectionTree
                                                                    tree={tree}
                                                                    select={setSelectedSectionIndex}
                                                                    selectedIndex={selectedSectionIndex}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-nowrap">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Game</td>
                                                                        <td>Current Position</td>
                                                                        <td>View</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {sections[selectedSectionIndex]?.games.length ===
                                                                    0 ? (
                                                                        <NoDataTableRow colSpan={3} />
                                                                    ) : (
                                                                        sections[selectedSectionIndex]?.games?.map(
                                                                            (game) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={`game-info-${selectedSectionIndex}-${game.id}`}
                                                                                    >
                                                                                        <td>
                                                                                            <GameRowItem game={game} />
                                                                                        </td>
                                                                                        <td className="position">
                                                                                            {game.position}
                                                                                        </td>
                                                                                        <td>
                                                                                            {game.position! < 200 && (
                                                                                                <>
                                                                                                    <Button
                                                                                                        outline
                                                                                                        color="default"
                                                                                                        className="btn-table-icon"
                                                                                                        id={`screenshot-${game.id}`}
                                                                                                        onClick={() => {
                                                                                                            window.open(
                                                                                                                sections[
                                                                                                                    selectedSectionIndex
                                                                                                                ]
                                                                                                                    .section
                                                                                                                    .screenshot,
                                                                                                                '_blank'
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="ri-screenshot-2-fill" />
                                                                                                    </Button>
                                                                                                    <UncontrolledTooltip
                                                                                                        target={`screenshot-${game.id}`}
                                                                                                    >
                                                                                                        Screenshot
                                                                                                    </UncontrolledTooltip>
                                                                                                </>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
