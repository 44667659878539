import { ITreeNode } from '../../../utils/bulitSectionTree';
import { RatioDisplay } from '../../../Components/Common/Table/RatioDisplay';
import { isEmpty } from '../../../utils/Object';
import { formatFirstSectionName } from '../../../utils/formatFirstSectionName';

export const SectionTree = ({
    tree,
    select,
    selectedIndex,
}: {
    tree: ITreeNode;
    select: (id: number) => void;
    selectedIndex: number;
}) => {
    const renderTree = (node: ITreeNode, level: number = 0): React.ReactNode => {
        const children = Object.values(node.children);

        return node.name !== 'root' && node.name ? (
            <ul>
                <li
                    className={!isEmpty(children) ? 'label' : selectedIndex === node.index ? 'active' : ''}
                    onClick={() => {
                        isEmpty(children) && select(node.index!);
                    }}
                >
                    <strong>
                        {level > 1 && isEmpty(children) ? (
                            <>
                                <i className="ri-subtract-line" />{' '}
                            </>
                        ) : (
                            <></>
                        )}
                        {level === 1 ? formatFirstSectionName(node.name) : node.name}
                    </strong>
                    {isEmpty(children) && (
                        <RatioDisplay current={node.games.length} total={node.game_count!} type="warning" />
                    )}
                </li>
                {children.map((child) => renderTree(child, level + 1))}
            </ul>
        ) : (
            children.map((child) => renderTree(child, level + 1))
        );
    };

    return renderTree(tree);
};
