import React from 'react';
import Select from 'react-select';
import { SkipFilterOptions } from './SkipFilterOptions';

type SelectNFGSkipFilterProps = {
    setValue: (status: SkipFilterOptions) => void;
    defaultValue?: SkipFilterOptions;
};

const options = [
    { value: SkipFilterOptions.ALL, label: 'ALL' },
    { value: SkipFilterOptions.ACTIVE, label: 'Active Items' },
    { value: SkipFilterOptions.SKIPPED, label: 'Skipped Items' },
];

export const SelectNFGSkipFilter = ({ setValue, defaultValue }: SelectNFGSkipFilterProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            options={options}
            onChange={(e: { value: SkipFilterOptions; label: string }) => {
                setValue(e.value);
            }}
            defaultValue={options.find((option) => option.value === defaultValue)}
        />
    );
};
