import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadProviderSuggestions } from '../../helpers/loadProviderSuggestions';

type SelectProviderProps = {
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
    defaultValue?: number | any;
    defaultOptions?: any[];
};

export const SelectProvider = ({
    setValue,
    isInProgress,
    placeholder = 'Filter By Provider...',
    defaultValue = 0,
    defaultOptions = [],
}: SelectProviderProps) => {
    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            isInProgress={isInProgress}
            loadSuggestions={() => loadProviderSuggestions()}
            placeholder={placeholder}
            hideImage={true}
            defaultValue={defaultValue}
            defaultOptions={defaultOptions}
        />
    );
};
