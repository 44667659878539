import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../../utils/usePrevious';
import { fetchPositionChangeStats } from '../../../slices/reports/thunk';
import { IGame } from '../../../Services/Api/IReportApiService';
import ReactApexChart from 'react-apexcharts';
import { COLORS } from '../../../common/data/colors';
import { positionChangeStatsSelector } from '../../../selectors/reportsSelectors';
import { formatDate } from '../../../utils/dates';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    change: IGame | undefined;
}

export const PositionChangeChartDialog = ({ isOpen, onClose, change }: IProps) => {
    const dispatch: any = useDispatch();
    const prevChangeId = usePrevious(change?.change_id as number);
    const { stats, isInProgress } = useSelector(positionChangeStatsSelector);

    useEffect(() => {
        if (isOpen && prevChangeId !== change?.change_id && change) {
            dispatch(fetchPositionChangeStats(change.id, change.section!.id));
        }
    }, [change, isOpen]);

    const series = [
        {
            name: 'Position',
            data: isInProgress ? [] : stats.map((row) => row.position),
        },
    ];
    const options: any = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        markers: {
            size: 4,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        colors: COLORS,
        yaxis: {
            tickAmount: 5,
            labels: {
                formatter: (val: number) => Math.floor(val),
            },
            reversed: true,
        },
        xaxis: {
            categories: isInProgress
                ? []
                : stats.map((row) =>
                      new Date(row.date).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                      })
                  ),
        },
    };
    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-lg"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    onClose();
                }}
            >
                Position updates
            </ModalHeader>
            <ModalBody className="text-center">
                <ReactApexChart
                    dir="ltr"
                    options={options}
                    series={series}
                    type="line"
                    height="350"
                    className="apex-charts"
                />
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col lg={5}>
                        <Button className="c-btn" color="light" onClick={() => onClose()}>
                            Close
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
};
