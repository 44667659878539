import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllJobs, reRunJob, deleteJob, fetchJobsStats } from '../../../slices/jobs/thunk';
import { Pagination } from 'Components/Common/Pagination';
import { IFetchAllJobsApiRequestBody } from '../../../slices/types';
import { toast, ToastContainer } from 'react-toastify';
import { allJobsDataSelector } from '../../../selectors/jobsSelectors';
import { SelectOperator } from '../../../Components/Selects/SelectOperator';
import { formatDate, formattedDateTime } from '../../../utils/dates';
import { JOB_STATUSES } from '../../../Services/Api/Job/IJobApiService';
import { SelectJobStatus } from '../../../Components/Selects/SelectJobStatus';
import { get } from 'lodash';
import flags from '../../../common/flags';
import { OperatorScraperTypes, VisibleInOptions } from '../../../Services/Api/IOperatorApiService';
import { SelectVisibleIn } from '../../../Components/Selects/SelectVisibleIn';
import { resetJobsReducerStatus } from '../../../slices/jobs/reducer';
import { SelectOperatorScraperType } from '../../../Components/Selects/SelectOperatorScraperType';
import { JobsChart } from './JobsChart';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { Search } from '../../../Components/Common/Search';
import { RatioDisplay } from '../../../Components/Common/Table/RatioDisplay';
import { JobStatuses } from './JobStatuses';

/* eslint-disable no-restricted-globals */
export const AllJobs = () => {
    const dispatch: any = useDispatch();

    const { data, isInProgress, isJobInProgress, isDeleteInProgress, message, success, totalPages, stats } =
        useSelector(allJobsDataSelector);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [jobId, setJobId] = useState<number>(0);
    const [params, setParams] = useState<IFetchAllJobsApiRequestBody>({
        page: 1,
        page_size: 25,
        operator_id: 0,
        status: JOB_STATUSES.ALL,
        original_status: JOB_STATUSES.ALL,
        visible_in: VisibleInOptions.ALL,
        message: '',
        scraper_type: 'all',
    });

    const handleSelect = (operatorId: any) => {
        setSelectedPage(1);
        setParams({ ...params, page: 1, operator_id: operatorId });
    };

    const changeScraperType = (scraperType: OperatorScraperTypes | 'all') => {
        setSelectedPage(1);
        setParams({ ...params, page: 1, scraper_type: scraperType });
    };
    const changePage = (page: number) => {
        setSelectedPage(page);
        setParams({ ...params, page: page });
    };
    const changeStatus = (status: JOB_STATUSES): void => {
        setParams({ ...params, page: 1, status });
    };
    const changeOriginalStatus = (status: JOB_STATUSES): void => {
        setParams({ ...params, page: 1, original_status: status });
    };
    const changeVisibleIn = (visible_in: VisibleInOptions): void => {
        setParams({ ...params, page: 1, visible_in });
    };

    const search = (query: string) => {
        setSearchTerm(query);
        setSelectedPage(1);
        setParams({ ...params, page: 1, q: query });
    };

    const reRun = (original_id: number): void => {
        setJobId(original_id);
        dispatch(reRunJob({ original_id }));
    };
    const onDelete = (original_id: number): void => {
        dispatch(deleteJob(original_id));
    };

    useEffect(() => {
        dispatch(fetchAllJobs(params));
    }, [params]);

    useEffect(() => {
        dispatch(fetchJobsStats());
    }, []);

    useEffect(() => {
        if (isJobInProgress && jobId) {
            dispatch(fetchAllJobs(params));
            setJobId(0);
        }
    }, [isJobInProgress]);

    useEffect(() => {
        if (!isDeleteInProgress && success !== null) {
            dispatch(resetJobsReducerStatus());
            dispatch(fetchAllJobs(params));

            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetJobsReducerStatus());
                    },
                });
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetJobsReducerStatus());
                    },
                });
            }
        }
    }, [isDeleteInProgress]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'All Jobs'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <JobsChart data={stats} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={6} sm={2}>
                                                        <Search search={search} placeholder="Search By ID..." />
                                                    </Col>
                                                    <Col xs={6} sm={2}>
                                                        <SelectOperator
                                                            setValue={handleSelect}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Operator..."
                                                        />
                                                    </Col>
                                                    <Col xs={6} sm={2}>
                                                        <SelectOperatorScraperType
                                                            setValue={changeScraperType}
                                                            placeholder="Filter By Scraper Type"
                                                            allOption={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <SelectJobStatus
                                                            setValue={changeStatus}
                                                            placeholder="Parsing Status"
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={2}>
                                                        <SelectJobStatus
                                                            setValue={changeOriginalStatus}
                                                            placeholder="Scrapping Status"
                                                        />
                                                    </Col>
                                                    <Col xs={6} sm={2}>
                                                        <SelectVisibleIn setValue={changeVisibleIn} />
                                                    </Col>
                                                    <Col sm={12} className="display-flex justify-content-end pt-3">
                                                        <Button onClick={() => dispatch(fetchAllJobs(params))}>
                                                            Refresh
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <td className="min-w">ID</td>
                                                                    <td scope="col">Operator</td>
                                                                    <td scope="col">Parsing ● Scrapping</td>
                                                                    <td scope="col">Error Message</td>
                                                                    <td scope="col">Games</td>
                                                                    <td scope="col">Json</td>
                                                                    <td scope="col">Date</td>
                                                                    <td scope="col" className="text-end min-w" />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((job: any) => (
                                                                    <tr key={`job-${job.original_id}`}>
                                                                        <td>{job.original_id}</td>
                                                                        <td>
                                                                            <div className="mb-2">
                                                                                <img
                                                                                    src={get(
                                                                                        flags,
                                                                                        `${job.operator.visible_in}.flag`
                                                                                    )}
                                                                                    alt="Skote"
                                                                                    className="me-2 rounded"
                                                                                    height="18"
                                                                                />
                                                                                {job.operator.name}{' '}
                                                                                {job.operator.scraper_type ===
                                                                                'pptr' ? (
                                                                                    <Badge color="success">pptr</Badge>
                                                                                ) : (
                                                                                    <Badge color="warning">wdio</Badge>
                                                                                )}{' '}
                                                                                {job.operator.proxy_needed === 1 && (
                                                                                    <Badge color="success">Proxy</Badge>
                                                                                )}{' '}
                                                                                {job.tries > 1 && <>({job.tries})</>}
                                                                            </div>
                                                                            <JobStatuses
                                                                                statuses={job.prev_statuses}
                                                                                id={job.id}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {job.status === 'in_progress' ? (
                                                                                <Badge color="info">In Progress</Badge>
                                                                            ) : job.status === 'done' ? (
                                                                                <Badge color="success">Done</Badge>
                                                                            ) : (
                                                                                <Badge color="danger">Failed</Badge>
                                                                            )}{' '}
                                                                            ●{' '}
                                                                            {job.original_status === 'in_progress' ? (
                                                                                <Badge color="info">In Progress</Badge>
                                                                            ) : job.original_status === 'done' ? (
                                                                                <Badge color="success">Done</Badge>
                                                                            ) : (
                                                                                <Badge color="danger">Failed</Badge>
                                                                            )}
                                                                        </td>
                                                                        <td className="multiline-td">{job.message}</td>
                                                                        <td>
                                                                            <RatioDisplay
                                                                                type="warning"
                                                                                current={job.games_parsed}
                                                                                total={
                                                                                    job.games_parsed +
                                                                                    job.not_found_games
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <a href={job.info_url} target="_blank">
                                                                                    View json
                                                                                </a>
                                                                            </div>
                                                                            {job.error_image && (
                                                                                <div>
                                                                                    <a
                                                                                        href={job.error_image}
                                                                                        target="_blank"
                                                                                    >
                                                                                        Screenshot
                                                                                    </a>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <Badge color="info">Run at</Badge>{' '}
                                                                                {formattedDateTime(job.created_at)}
                                                                            </div>
                                                                            <div>
                                                                                <Badge color="info">Date</Badge>{' '}
                                                                                {formatDate(new Date(job.date), {
                                                                                    month: 'short',
                                                                                    day: 'numeric',
                                                                                })}
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            {!isJobInProgress && (
                                                                                <Button
                                                                                    color={
                                                                                        job.status !== 'in_progress'
                                                                                            ? 'warning'
                                                                                            : 'success'
                                                                                    }
                                                                                    size="sm"
                                                                                    onClick={() =>
                                                                                        reRun(job.original_id)
                                                                                    }
                                                                                >
                                                                                    Re-run
                                                                                </Button>
                                                                            )}
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        //eslint-disable-line
                                                                                        confirm(
                                                                                            'Are you sure you want to delete this job'
                                                                                        )
                                                                                    ) {
                                                                                        onDelete(job.original_id);
                                                                                    }
                                                                                }}
                                                                                className="ms-2"
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination currentPage={selectedPage} totalPages={totalPages} changePage={changePage} />
                </Container>
                <ToastContainer />
            </div>
        </>
    );
};
