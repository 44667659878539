import { DropDown } from '../Common/DropDown';
import React from 'react';
import { loadSelectedOperatorSuggestions } from '../../helpers/loadSelectedOperatorSuggestions';
import { useSelector } from 'react-redux';
import { userRelatedDataSelector } from '../../selectors/accountSelectors';
import { IUserRelatedOperator } from '../../Services/Api/Account/IAccountApiService';

type SelectSelectedOperatorProps = {
    value?: number | number[];
    setValue: (id: number) => void;
    isInProgress: boolean;
    placeholder?: string;
    isMulti?: boolean;
    defaultValue?: any;
    isClearable?: boolean;
    operators?: IUserRelatedOperator[];
};

export const SelectSelectedOperator = ({
    value,
    setValue,
    isInProgress,
    placeholder = 'Filter By Operator...',
    defaultValue,
    isMulti = false,
    isClearable = true,
    operators = [],
}: SelectSelectedOperatorProps) => {
    const { operators : selectedOperators } = useSelector(userRelatedDataSelector);

    const options = (operators.length > 0 ? operators : selectedOperators).map((operator: IUserRelatedOperator) => {
        return { label: operator.name, value: operator.id };
    });

    return (
        <DropDown
            handleSelect={(id) => {
                setValue(id);
            }}
            value={value}
            defaultOptions={options}
            isInProgress={isInProgress}
            loadSuggestions={() => loadSelectedOperatorSuggestions()}
            placeholder={placeholder}
            hideImage={true}
            isMulti={isMulti}
            isClearable={isClearable}
            defaultValue={defaultValue}
        />
    );
};
