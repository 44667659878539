import { createSlice } from '@reduxjs/toolkit';
import { IAccountState } from '../../types';
import { User } from '../../../Models/User';

export const initialState: IAccountState = {
    account: null,
    relatedData: {
        operators: [],
        games: [],
    },
    isInProgress: {
        fetchUserRelatedData: false,
        updateAccountDetails: false,
        updatePassword: false,
    },
    message: '',
    success: null,
};

const AccountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccountInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        updateAccountDetailsAction(state, action) {
            state.isInProgress.updateAccountDetails = false;
            state.message = Array.isArray(action.payload.message)
                ? action.payload.message.join(', <br />')
                : action.payload.message;
            state.success = action.payload.code === 101;
        },
        updatePasswordAction(state, action) {
            state.isInProgress.updatePassword = false;
            state.message = Array.isArray(action.payload.message)
                ? action.payload.message.join(', <br />')
                : action.payload.message;
            state.success = action.payload.code === 101;

            if (action.payload.code === 101) {
                state.account = new User(action.payload.data);
            }
        },
        resetAccountReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
        fetchUserRelatedDataSuccessAction(state, action: { payload: any }) {
            state.relatedData = action.payload;
            state.isInProgress.fetchUserRelatedData = false;
        },
    },
});

export const {
    updateAccountDetailsAction,
    updatePasswordAction,
    fetchUserRelatedDataSuccessAction,
    resetAccountReducerStatus,
    setAccountInProgressAction,
} = AccountSlice.actions;

export default AccountSlice.reducer;
