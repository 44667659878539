import { BaseApiService } from './BaseApiService';
import {
    IReportGamesOnOperatorResponse,
    IReportPortfolioResponse,
    IReportPositionUpdatesResponse,
    IReportScoresResponse,
} from './IReportApiService';

export class ReportApiService {
    static FETCH_REPORT_GAMES_ON_OPERATOR_URL = '/reports/games-on-operator';
    static FETCH_REPORT_PORTFOLIO_URL = '/reports/portfolio';
    static FETCH_REPORT_SCORES_URL = '/reports/scores';
    static FETCH_REPORT_GAMES_ON_SECTIONS_URL = '/reports/games-on-sections';
    static FETCH_REPORT_POSITION_UPDATES_URL = '/reports/position-updates';
    static FETCH_POSITION_CHANGE_STATS_URL = '/reports/position-change-stats';

    static getGamesOnOperator(providerId: number, gameId: number, operatorId: number) {
        return BaseApiService.get<IReportGamesOnOperatorResponse>(ReportApiService.FETCH_REPORT_GAMES_ON_OPERATOR_URL, {
            operator_id: operatorId,
            game_id: gameId,
            provider_id: providerId,
        });
    }

    static getPortfolio(providerId: number, gameId: number, operatorId: number) {
        return BaseApiService.get<IReportPortfolioResponse>(ReportApiService.FETCH_REPORT_PORTFOLIO_URL, {
            provider_id: providerId,
            game_id: gameId,
            operator_id: operatorId,
        });
    }

    static getScores(providerId: number, gameId: number, operatorId: number) {
        return BaseApiService.get<IReportScoresResponse>(ReportApiService.FETCH_REPORT_SCORES_URL, {
            provider_id: providerId,
            game_id: gameId,
            operator_id: operatorId,
        });
    }

    static getGamesOnSections(gameId: number, operatorId: number) {
        return BaseApiService.get<IReportPortfolioResponse>(ReportApiService.FETCH_REPORT_GAMES_ON_SECTIONS_URL, {
            game_id: gameId,
            operator_id: operatorId,
        });
    }

    static getPositionUpdates(providerId: number, gameIds: number[], operatorIds: number[], date: string) {
        return BaseApiService.get<IReportPositionUpdatesResponse>(ReportApiService.FETCH_REPORT_POSITION_UPDATES_URL, {
            provider_id: providerId,
            date,
            ...(gameIds.length > 0 ? { game_ids: gameIds } : {}),
            ...(operatorIds.length > 0 ? { operator_ids: operatorIds } : {}),
        });
    }

    static getPositionChangeStats(gameId: number, sectionId: number) {
        return BaseApiService.get<any>(ReportApiService.FETCH_POSITION_CHANGE_STATS_URL, {
            game_id: gameId,
            section_id: sectionId,
        });
    }
}
