import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';

// Authentication
import LoginReducer from './auth/login/reducer';
import RegisterReducer from './auth/register/reducer';
import ForgetPasswordReducer from './auth/forgetpwd/reducer';
import AccountReducer from './auth/account/reducer';

import GamesReducer from './games/reducer';
import GlobalReducer from './global/reducer';
import ProvidersReducer from './providers/reducer';
import ReportsReducer from './reports/reducer';
import OperatorsReducer from './operators/reducer';
import ClientOperatorsReducer from './client-operators/reducer';
import ClientGamesReducer from './client-games/reducer';
import JobsReducer from './jobs/reducer';
import DashboardReducer from './dashboard/reducer';

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    Register: RegisterReducer,
    ForgetPassword: ForgetPasswordReducer,
    Games: GamesReducer,
    Providers: ProvidersReducer,
    Reports: ReportsReducer,
    Global: GlobalReducer,
    Operators: OperatorsReducer,
    ClientOperators: ClientOperatorsReducer,
    ClientGames: ClientGamesReducer,
    Jobs: JobsReducer,
    Dashboard: DashboardReducer,
});

export default rootReducer;
