import { createSlice } from '@reduxjs/toolkit';
import { IGlobalState } from '../types';

export const initialState: IGlobalState = {
    isInProgress: false,
    portfolioMoreGamesDialog: {
        games: [],
        isOpen: false,
        position: {
            top: 0,
            left: 0,
        },
    },
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setGlobalInProgressAction(state, action: { payload: boolean }) {
            state.isInProgress = action.payload;
        },
        setPortfolioMoreGamesDialogDataAction(state, action: { payload: IGlobalState['portfolioMoreGamesDialog'] }) {
            state.portfolioMoreGamesDialog = action.payload;
        },
        resetPortfolioMoreGamesDialogDataAction(state) {
            state.portfolioMoreGamesDialog = initialState.portfolioMoreGamesDialog;
        },
    },
});

export const {
    setGlobalInProgressAction,
    setPortfolioMoreGamesDialogDataAction,
    resetPortfolioMoreGamesDialogDataAction,
} = globalSlice.actions;

export default globalSlice.reducer;
