import { createSelector } from 'reselect';

export const loginStateSelector = (state: any) => state.Login;
export const registerStateSelector = (state: any) => state.Register;

export const loginDataSelector = createSelector(loginStateSelector, (login) => ({
    error: login.error,
    errorMsg: login.errorMsg,
}));

export const registerDataSelector = createSelector(registerStateSelector, (register) => {
    return {
        success: register.success,
        error: register.error,
        loading: register.loading,
        registrationError: register.registrationError,
    };
});
