import {
    fetchAllGamesSuccessAction,
    fetchNotFoundGamesSuccessAction,
    setGamesInProgressAction,
    matchGamesSuccessAction,
    matchGamesFailedAction,
    createAndMatchGameSuccessAction,
    toggleIdentifyByImageSuccessAction,
    mergeGamesSuccessAction,
    attachProviderByURLsSuccessAction,
    toggleSkipGameSuccessAction,
    setGamesFiltersAction,
    updateGameSuccessAction, createGameSuccessAction,
} from './reducer'
import {
    fetchNotFoundGamesApi,
    matchGamesApi,
    createAndMatchGameApi,
} from '../../helpers/backend_helper';
import {
    IAttachProviderByURLsApiRequestBody,
    ICreateAndMatchGameApiRequestBody,
    IFetchAllGamesApiRequestBody,
    IFetchNotFoundGamesApiRequestBody,
    IGameApiRequestBody,
    IMatchGamesApiRequestBody,
    IMergeProvidersApiRequestBody,
    IState,
} from '../types';
import { setGlobalInProgressAction } from 'slices/global/reducer';
import { GameApiService } from '../../Services/Api/GameApiService';
import { IToggleIdentifyGameByImageApiRequestBody } from '../../Services/Api/IGameApiService';

export const fetchAllGames =
    (params?: IFetchAllGamesApiRequestBody) => async (dispatch: any, getState: () => IState) => {
        dispatch(setGamesInProgressAction('fetchAllGames'));
        !params?.q && dispatch(setGlobalInProgressAction(true));
        try {
            let localFilters = params;
            if (params) {
                dispatch(setGamesFiltersAction({ key: 'fetchAllGames', value: params }));
            } else {
                localFilters = getState().Games.filters.fetchAllGames;
            }

            const data = await GameApiService.fetchAllGamesApi(localFilters!);

            if (data.code === 101) {
                dispatch(fetchAllGamesSuccessAction(data));
            }
        } catch (error) {}
        !params?.q && dispatch(setGlobalInProgressAction(false));
    };

export const fetchNotFoundGames =
    (params?: IFetchNotFoundGamesApiRequestBody) => async (dispatch: any, getState: () => IState) => {
        dispatch(setGamesInProgressAction('fetchNotFoundGames'));
        dispatch(setGlobalInProgressAction(true));
        try {
            let localFilters = params;
            if (params) {
                dispatch(setGamesFiltersAction({ key: 'fetchNotFoundGames', value: params }));
            } else {
                localFilters = getState().Games.filters.fetchNotFoundGames;
            }

            const data = await fetchNotFoundGamesApi(localFilters!);

            if (data.code === 101) {
                dispatch(fetchNotFoundGamesSuccessAction(data));
            }
        } catch (error) {}
        dispatch(setGlobalInProgressAction(false));
    };

export const matchGames = (params: IMatchGamesApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('matchGames'));
    try {
        const data = await matchGamesApi(params);

        if (data.code === 101) {
            dispatch(matchGamesSuccessAction(data.message));
        } else {
            dispatch(matchGamesFailedAction(data.message));
        }
    } catch (error) {}
};

export const createAndMatchGame = (params: ICreateAndMatchGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('createAndMatchGame'));
    try {
        const data = await createAndMatchGameApi(params);

        if (data.code === 101) {
            dispatch(createAndMatchGameSuccessAction(data.message));
        } else {
            dispatch(matchGamesFailedAction(data.message));
        }
    } catch (error) {}
};

export const toggleIdentifyByImage = (params: IToggleIdentifyGameByImageApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('toggleIdentifyByImage'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await GameApiService.toggleIdentifyGameByImage(params);
        if (data.code === 101) {
            dispatch(toggleIdentifyByImageSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const toggleSkipGame = (params: IToggleIdentifyGameByImageApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('toggleSkipGame'));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await GameApiService.toggleSkipGame(params);
        if (data.code === 101) {
            dispatch(toggleSkipGameSuccessAction(data));
            dispatch(fetchNotFoundGames());
        } else {
            dispatch(setGlobalInProgressAction(false));
        }
    } catch (error) {
        dispatch(setGlobalInProgressAction(false));
    }
};

export const mergeGames = (params: IMergeProvidersApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('mergeGames'));
    try {
        const data = await GameApiService.mergeGames(params);

        dispatch(mergeGamesSuccessAction({ message: data.message, success: data.code === 101 }));
    } catch (error) {}
};

export const updateGame = (id: number, params: IGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setGlobalInProgressAction(true));
    dispatch(setGamesInProgressAction('updateGame'));
    try {
        const data = await GameApiService.updateGame(id, params);

        dispatch(updateGameSuccessAction({ message: data.message, success: data.code === 101 }));
        dispatch(fetchAllGames());
    } catch (error) {}
};

export const createGame = (params: IGameApiRequestBody) => async (dispatch: any) => {
    dispatch(setGlobalInProgressAction(true));
    dispatch(setGamesInProgressAction('createGame'));
    try {
        const data = await GameApiService.createGame(params);

        dispatch(createGameSuccessAction({ message: data.message, success: data.code === 101 }));
        dispatch(fetchAllGames());
    } catch (error) {}
};

export const attachProviderByURLs = (params: IAttachProviderByURLsApiRequestBody) => async (dispatch: any) => {
    dispatch(setGamesInProgressAction('attachProviderByURLs'));
    try {
        const data = await GameApiService.attachProviderByURLs(params);

        if (data.code === 101) {
            dispatch(attachProviderByURLsSuccessAction(data.message));
        }
    } catch (error) {}
};
