import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LessItemsIcon } from '../../../Layouts/SVG/InfoCard/LessItemsIcon';
import { portfolioMoreGamesDialogSelector } from '../../../selectors/globalSelectors';
import { resetPortfolioMoreGamesDialogDataAction } from '../../../slices/global/reducer';

export const PortfolioSeeMoreGamesDialog = () => {
    const dispatch: any = useDispatch();

    const closePopup = () => {
        dispatch(resetPortfolioMoreGamesDialogDataAction());
    };

    const { games, isOpen, position } = useSelector(portfolioMoreGamesDialogSelector);

    return isOpen ? (
        <>
            <div
                className="more-games-popup"
                style={{
                    position: 'absolute',
                    top: position.top,
                    left: position.left,
                }}
            >
                <div className="body">
                    <ul>
                        {games.map((game) => (
                            <li key={`missing-popup-game-${game.id}`}>{game.name}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="more-games-overlay" onClick={closePopup}></div>
        </>
    ) : (
        <></>
    );
};
