import React, { useEffect } from 'react';
import { Row, Col, Alert, Input, Label, Form, FormFeedback, Button, Spinner } from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux';
import { updatePassword } from '../../slices/auth/account/thunk';
import { passwordDataSelector } from '../../selectors/accountSelectors';
import { resetAccountReducerStatus } from '../../slices/auth/account/reducer';

export const PasswordTab = () => {
    const dispatch: any = useDispatch();

    const password = useFormik({
        enableReinitialize: true,

        initialValues: {
            user_password: '',
            user_npassword: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            user_password: Yup.string().required('Please Enter Your Current Password'),
            user_npassword: Yup.string().required('Please Enter Your New Password'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('user_npassword'), 'Passwords do not match'])
                .required('Confirm Password is required'),
        }),
        onSubmit: (values) => {
            dispatch(updatePassword(values));
        },
    });
    const { isInProgress, message, success } = useSelector(passwordDataSelector);

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetAccountReducerStatus());
                    },
                });
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetAccountReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                password.handleSubmit();
                return false;
            }}
            className="needs-validation"
            action="#"
        >
            <Row>
                <Col xs={12} sm={4}>
                    <div className="mb-3">
                        <Label htmlFor="userpassword" className="form-label">
                            Current Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                            name="user_password"
                            type="password"
                            placeholder="Enter Current Password"
                            onChange={password.handleChange}
                            onBlur={password.handleBlur}
                            value={password.values.user_password || ''}
                            invalid={password.touched.user_password && password.errors.user_password ? true : false}
                        />
                        {password.touched.user_password && password.errors.user_password ? (
                            <FormFeedback type="invalid">
                                <div>{password.errors.user_password}</div>
                            </FormFeedback>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="userpassword" className="form-label">
                            New Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                            name="user_npassword"
                            type="password"
                            placeholder="Enter New Password"
                            onChange={password.handleChange}
                            onBlur={password.handleBlur}
                            value={password.values.user_npassword || ''}
                            invalid={password.touched.user_npassword && password.errors.user_npassword ? true : false}
                        />
                        {password.touched.user_npassword && password.errors.user_npassword ? (
                            <FormFeedback type="invalid">
                                <div>{password.errors.user_npassword}</div>
                            </FormFeedback>
                        ) : null}
                    </div>

                    <div className="mb-2">
                        <Label htmlFor="confirmPassword" className="form-label">
                            Confirm New Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={password.handleChange}
                            onBlur={password.handleBlur}
                            value={password.values.confirm_password || ''}
                            invalid={
                                password.touched.confirm_password && password.errors.confirm_password ? true : false
                            }
                        />
                        {password.touched.confirm_password && password.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                                <div>{password.errors.confirm_password}</div>
                            </FormFeedback>
                        ) : null}
                    </div>

                    <div className="mt-4">
                        <Button color="success" type="submit" disabled={isInProgress}>
                            {isInProgress && (
                                <Spinner size="sm" className="me-2">
                                    {' '}
                                    Loading...{' '}
                                </Spinner>
                            )}
                            Save
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
