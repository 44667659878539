import { toCapitalCase } from './toCapitalCase';

export const formatFirstSectionName = (section: string): string => {
    if (['casino'].includes(section)) {
        return 'Casino';
    } else if (['category'].includes(section)) {
        return 'Casino Menu';
    } else if (['menu', 'home'].includes(section)) {
        return toCapitalCase(section);
    }
    return section;
};
