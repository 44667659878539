import loading from '../../assets/images/loading.gif';
import { useEffect, useState } from 'react';

export const LoadingScreen = () => {
    const [width, setWidth] = useState<string>('100%');
    useEffect(() => {
        const element = document.getElementsByClassName('page-content')[0];

        if (element) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    const newWidth = entry.contentRect.width;
                    if (newWidth) {
                        setWidth(`${newWidth + 27 * 2}px`);
                    }
                }
            });

            resizeObserver.observe(element);
        }
    }, []);
    return (
        <div className="loading-screen" style={{ width }}>
            <img src={loading} alt="Please wait..." />
        </div>
    );
};
