import { createSelector } from 'reselect';
import { IGlobalState } from '../slices/types';

export const globalStateSelector = (state: any): IGlobalState => state.Global;

export const globalDataSelector = createSelector(globalStateSelector, (global) => ({
    isInProgressGlobal: global.isInProgress,
}));

export const portfolioMoreGamesDialogSelector = createSelector(
    globalStateSelector,
    (global) => global.portfolioMoreGamesDialog
);
