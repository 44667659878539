import React from 'react';
import { IJobStat } from '../../../Services/Api/Job/IJobApiService';
import ReactApexChart from 'react-apexcharts';

const parseToData = (data: IJobStat[]) => {
    const done: number[] = [];
    const inProgress: number[] = [];
    const failed: number[] = [];
    const dates: string[] = [];
    data.forEach((stat) => {
        done.push(stat.done ?? 0);
        inProgress.push(stat.in_progress ?? 0);
        failed.push(stat.failed ?? 0);
        dates.push(stat.date);
    });
    return {
        done,
        inProgress,
        failed,
        dates,
    };
};
export const JobsChart = ({ data }: { data: IJobStat[] }) => {
    const { done, inProgress, failed, dates } = parseToData(data);
    const s = {
        series: [
            {
                name: 'Done',
                data: done,
            },
            {
                name: 'Failed',
                data: failed,
            },
            {
                name: 'In Progress',
                data: inProgress,
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
            },
            colors: ['#00E396', '#FE4560', '#008FFB'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900,
                            },
                        },
                    },
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff'],
            },
            xaxis: {
                categories: dates,
            },
            yaxis: {
                title: {
                    text: undefined,
                },
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40,
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={s.options}
                series={s.series}
                height="380"
                className="apex-charts"
                type="bar"
            />
        </React.Fragment>
    );
};
