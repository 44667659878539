import { setGlobalInProgressAction } from '../global/reducer';
import {
    fetchDashboardDataForPositionUpdatesSuccessAction,
    fetchDashboardDataForScoresAction,
    setDashboardInProgressAction,
} from './reducer';
import { DashboardApiService } from '../../Services/Api/DashboardApiService';

export const fetchDashboardDataForPositionChange = () => async (dispatch: any) => {
    dispatch(setDashboardInProgressAction(`fetchDashboardDataForPositionUpdates`));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await DashboardApiService.getDashboardDataForPositionUpdates();

        if (data.code === 101) {
            dispatch(fetchDashboardDataForPositionUpdatesSuccessAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};

export const fetchDashboardDataForScores = () => async (dispatch: any) => {
    dispatch(setDashboardInProgressAction(`fetchDashboardDataForScores`));
    dispatch(setGlobalInProgressAction(true));
    try {
        const data = await DashboardApiService.getDashboardDataForScores();

        if (data.code === 101) {
            dispatch(fetchDashboardDataForScoresAction(data));
        }
    } catch (error) {}
    dispatch(setGlobalInProgressAction(false));
};
