import { createSlice } from '@reduxjs/toolkit';
import { IRegisterState } from '../../types';

export const initialState: IRegisterState = {
    registrationError: null,
    loading: false,
    user: null,
    isUserLogout: true,
    isInProgress: {
        registerUser: false,
    },
    error: false,
    message: '',
    success: null,
};

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        registerUserSuccessful(state: any, action: any) {
            state.user = action.payload.user;
            state.loading = false;
            state.success = true;
            state.registrationError = null;
            state.isInProgress.registerUser = false;
        },
        registerUserFailed(state: any, action: any) {
            state.user = null;
            state.loading = false;
            state.registrationError = action.payload.message.join(', ');
            state.error = true;
        },
        resetRegisterFlagChange(state: any) {
            state.success = false;
            state.error = false;
        },
        apiErrorChange(state: any, action: any) {
            state.error = action.payload;
            state.loading = false;
            state.isUserLogout = false;
        },
        setRegisterInProgressActions(state: any, action: { payload: keyof typeof initialState.isInProgress }) {
            state.loading = true;
            state.isInProgress[action.payload] = true;
        },
    },
});

export const {
    registerUserSuccessful,
    registerUserFailed,
    resetRegisterFlagChange,
    setRegisterInProgressActions,
} = registerSlice.actions;

export default registerSlice.reducer;
