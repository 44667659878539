import { createSelector } from 'reselect';
import { IState } from '../slices/types';

export const accountStateSelector = (state: IState) => state.Account;

export const userRelatedDataSelector = createSelector(accountStateSelector, (account) => {
    return account.relatedData;
});

export const userRelatedFirstOperatorSelector = createSelector(accountStateSelector, (account) => {
    return account.relatedData.operators.length > 0 ? account.relatedData.operators[0] : null;
});

export const userRelatedFirstGameSelector = createSelector(accountStateSelector, (account) => {
    return account.relatedData.games.length > 0 ? account.relatedData.games[0] : null;
});

export const userRelatedDataCountsSelector = createSelector(
    accountStateSelector,
    (
        account
    ): {
        games: number;
        operators: number;
    } => {
        return {
            games: account.relatedData.games.length,
            operators: account.relatedData.operators.length,
        };
    }
);

export const accountDetailsDataSelector = createSelector(accountStateSelector, (account) => {
    return {
        isInProgress: account.isInProgress.updateAccountDetails,
        message: account.message,
        success: account.success,
    };
});

export const passwordDataSelector = createSelector(accountStateSelector, (account) => {
    return {
        isInProgress: account.isInProgress.updatePassword,
        message: account.message,
        success: account.success,
    };
});
