import {
    fetchUserRelatedDataSuccessAction,
    setAccountInProgressAction,
    updateAccountDetailsAction,
    updatePasswordAction,
} from './reducer';
import {
    IAccountDetailsUpdateApiRequestBody,
    IPasswordUpdateApiRequestBody,
} from '../../../Services/Api/Account/IAccountApiService';
import { AccountApiService } from '../../../Services/Api/Account/AccountApiService';
import { updateLoggedinUser } from '../../../helpers/api_helper';

export const updateAccountDetails = (params: IAccountDetailsUpdateApiRequestBody) => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('updateAccountDetails'));
    try {
        const data = await AccountApiService.updateAccountDetails(params);

        dispatch(updateAccountDetailsAction(data));

        if (data.code === 101) {
            updateLoggedinUser(data.data);
        }
    } catch (error) {}
};

export const updatePassword = (params: IPasswordUpdateApiRequestBody) => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('updatePassword'));
    try {
        const data = await AccountApiService.updatePassword(params);

        dispatch(updatePasswordAction(data));
    } catch (error) {}
};

export const fetchUserRelatedData = () => async (dispatch: any) => {
    dispatch(setAccountInProgressAction('fetchUserRelatedData'));
    try {
        const data = await AccountApiService.fetchUserRelatedData();

        if (data.code === 101) {
            dispatch(fetchUserRelatedDataSuccessAction(data.data));
        }
    } catch (error) {}
};
