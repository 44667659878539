import {
    Badge,
    Button,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { attachProviderByURLs, createAndMatchGame, matchGames } from '../../../slices/games/thunk';
import { attachProviderByURLsDataSelector, matchGamesDataSelector } from '../../../selectors/gamesSelectors';
import { toast } from 'react-toastify';
import { resetGamesReducerStatus } from '../../../slices/games/reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectProvider } from '../../../Components/Selects/SelectProvider';
import { SelectOperator } from '../../../Components/Selects/SelectOperator';

export const AttachProviderByURLsDialog = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
}) => {
    const dispatch: any = useDispatch();
    const [selectedProviderId, setSelectedProviderId] = useState<number>(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState<number>(0);

    const onAttachProviderByURLs = (urls: string): void => {
        dispatch(attachProviderByURLs({ provider_id: selectedProviderId, operator_id: selectedOperatorId, urls }));
    };
    const { message, success, isInProgress } = useSelector(attachProviderByURLsDataSelector);

    const handleClose = (refresh?: boolean) => {
        onClose(refresh);
    };

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            urls: '',
        },
        validationSchema: Yup.object({
            urls: Yup.string().required('Please enter any URL'),
        }),
        onSubmit: ({ urls }) => {
            onAttachProviderByURLs(urls);
        },
    });

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetGamesReducerStatus());
                    },
                });

                handleClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetGamesReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                handleClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-lg"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && handleClose();
                }}
            >
                Attach Provider by URLs
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
                className="needs-validation"
                action="#"
            >
                <ModalBody className="text-center">
                    <div className="mt-4">
                        <dl className="row mb-0">
                            <dt className="col-sm-3">Provider</dt>
                            <dd className="col-sm-9">
                                <SelectProvider setValue={setSelectedProviderId} isInProgress={isInProgress} />
                            </dd>

                            <dt className="col-sm-3">Operator</dt>
                            <dd className="col-sm-9">
                                <SelectOperator setValue={setSelectedOperatorId} isInProgress={isInProgress} />
                            </dd>

                            <dt className="col-sm-3">URLs</dt>
                            <dd className="col-sm-9">
                                <>
                                    <textarea
                                        name="urls"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        className="form-control"
                                        id="exampleFormControlTextarea"
                                        rows={20}
                                    />
                                    {validation.touched.urls && validation.errors.urls ? (
                                        <FormFeedback type="invalid">
                                            <div>{validation.errors.urls}</div>
                                        </FormFeedback>
                                    ) : null}
                                </>
                            </dd>
                        </dl>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Row className="full-width">
                        <Col lg={12}>
                            <Row>
                                <Col lg={5} className="d-grid p-0">
                                    <Button
                                        className="c-btn"
                                        color="light"
                                        onClick={() => handleClose()}
                                        disabled={isInProgress}
                                    >
                                        Close
                                    </Button>
                                </Col>
                                <Col lg={2} className="" />
                                <Col lg={5} className="d-grid p-0">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={isInProgress || !selectedProviderId || !selectedOperatorId}
                                    >
                                        {isInProgress ? (
                                            <span className="d-flex align-items-center">
                                                <Spinner className="top-25 start-50" animation="border" color="light" />
                                                <span className="flex-grow-1 ms-2">Loading...</span>
                                            </span>
                                        ) : (
                                            'Attach'
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
