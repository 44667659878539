import { Button, Form, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IGame } from '../../../Services/Api/IReportApiService';
import { resetGamesReducerStatus } from '../../../slices/games/reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createGame, updateGame } from '../../../slices/games/thunk';
import { SelectProvider } from '../../../Components/Selects/SelectProvider';
import { updateOperatorDataSelector } from '../../../selectors/gamesSelectors';

export const GameDialog = ({ isOpen, onClose, game }: { isOpen: boolean; onClose: () => void; game?: IGame }) => {
    const dispatch: any = useDispatch();

    const { message, success, isInProgress } = useSelector(updateOperatorDataSelector);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: game?.name || '',
            provider_id: game?.provider!.id || 0,
            image: game?.image || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            provider_id: Yup.string().required(),
        }),
        onSubmit: (values) => {
            if (!!game) {
                dispatch(updateGame(game.id, values));
            } else {
                dispatch(createGame(values));
            }
        },
    });

    useEffect(() => {
        console.log('isInProgress', isInProgress, success);
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {},
                });

                onClose();
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {},
                });
            }
            dispatch(resetGamesReducerStatus());
        }
    }, [isInProgress]);

    useEffect(() => {
        validation.resetForm();
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-md"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && onClose();
                }}
            >
                {game ? 'Edit game' : 'Add new game'}
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <ModalBody>
                    <div className="mb-3">
                        <label htmlFor="operatorName" className="form-label">
                            Name *
                        </label>
                        <Input
                            type="text"
                            className="form-control"
                            id="operatorName"
                            placeholder="Enter name"
                            name="name"
                            value={validation.values.name}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={!!(validation.touched.name && validation.errors.name)}
                        />
                        {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="provider" className="form-label">
                            Provider *
                        </label>

                        <SelectProvider
                            setValue={(value) => {
                                validation.setFieldValue('provider_id', value);
                            }}
                            isInProgress={false}
                            placeholder="Select scraper type"
                            defaultOptions={game ? [{ value: game?.provider!.id, label: game?.provider!.name }] : []}
                            defaultValue={game ? { value: game?.provider!.id, label: game?.provider!.name } : null}
                        />
                    </div>
                    {!game && (
                        <div className="mb-3">
                            <label htmlFor="image" className="form-label">
                                Image URL (optional)
                            </label>
                            <Input
                                type="text"
                                className="form-control"
                                id="image"
                                placeholder="Enter name"
                                name="image"
                                value={validation.values.image}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={!!(validation.touched.image && validation.errors.image)}
                            />
                            {validation.touched.image && validation.errors.image ? (
                                <FormFeedback type="invalid">{validation.errors.image}</FormFeedback>
                            ) : null}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => onClose()} disabled={isInProgress}>
                        Close
                    </Button>
                    <Button color="primary" type="submit" disabled={isInProgress || !validation.isValid}>
                        {isInProgress ? (
                            <span className="d-flex align-items-center">
                                <Spinner className="top-25 start-50" animation="border" color="light" />
                                <span className="flex-grow-1 ms-2">Loading...</span>
                            </span>
                        ) : game ? (
                            'Save'
                        ) : (
                            'Create'
                        )}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
