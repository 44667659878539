import { IGame, IReportOperator } from '../../../Services/Api/IReportApiService';
import { OperatorRowIcon } from '../../../Layouts/SVG/InfoCard/OperatorRowIcon';

export const OperatorRowItem = ({ operator }: { operator: IReportOperator }) => {
    return (
        <div className="operator-row-item">
            <OperatorRowIcon />
            {operator.name}
        </div>
    );
};
