import { Input } from 'reactstrap';
import { debounce } from 'lodash-es';

type ISearchProps = {
    search: (input: string) => any;
    placeholder?: string;
    onlyOnEnter?: boolean;
};

export const Search = ({ search, placeholder = 'Search...', onlyOnEnter = false }: ISearchProps) => {
    const _loadSuggestions = debounce((query: string) => search(query), 300);

    const onKeyDownPress = (e: any) => {
        const { key } = e;
        if (key === 'Enter') {
            _loadSuggestions(e.target.value);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onlyOnEnter) {
            _loadSuggestions(event.target.value);
        }
    };

    return (
        <Input
            type="text"
            className="form-control mb-2 mb-lg-0 mb-md-2 mb-sm-2"
            placeholder={placeholder}
            id="search-options"
            onKeyDown={onKeyDownPress}
            onChange={handleChange}
        />
    );
};
