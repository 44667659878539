import { BaseApiService } from './BaseApiService';
import { IDashboardDataForPositionUpdatesResponse, IDashboardDataForScoresResponse } from './IDashboardApiService';

export class DashboardApiService {
    static FETCH_DASHBOARD_DATA_FOR_POSITION_UPDATES_URL = '/dashboard/position-updates';
    static FETCH_DASHBOARD_DATA_FOR_SCORES_URL = '/dashboard/scores';

    static getDashboardDataForPositionUpdates() {
        return BaseApiService.get<IDashboardDataForPositionUpdatesResponse>(
            DashboardApiService.FETCH_DASHBOARD_DATA_FOR_POSITION_UPDATES_URL
        );
    }

    static getDashboardDataForScores() {
        return BaseApiService.get<IDashboardDataForScoresResponse>(
            DashboardApiService.FETCH_DASHBOARD_DATA_FOR_SCORES_URL
        );
    }
}
