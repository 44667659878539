import React, { useEffect, useState } from 'react';
import { Card, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolio } from '../../../slices/reports/thunk';
import { portfolioDataSelector } from '../../../selectors/reportsSelectors';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';
import { InfoCard } from '../../../Components/Common/InfoCard';
import { userRelatedDataCountsSelector } from '../../../selectors/accountSelectors';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { ClearFilters } from '../../../Components/Common/ClearFilters';
import { PortfolioTable } from './PortfolioTable';

export const Portfolio = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();

    const { isInProgress } = useSelector(portfolioDataSelector);
    const relatedDataCounts = useSelector(userRelatedDataCountsSelector);

    const [selectedProviderId] = useState(user.providerId);
    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'Missing Games'} />
                                <Row>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            title="Total Tracked Games"
                                            iconType="game"
                                            amount={relatedDataCounts.games}
                                        />
                                    </Col>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            title="Total Tracked Operators"
                                            iconType="operator"
                                            iconColor="warning"
                                            amount={relatedDataCounts.operators}
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedGame
                                                            setValue={setSelectedGameId}
                                                            value={selectedGameId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedOperator
                                                            value={selectedOperatorId}
                                                            setValue={setSelectedOperatorId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <ClearFilters
                                                            methods={[setSelectedOperatorId, setSelectedGameId]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <PortfolioTable
                                                selectedProviderId={selectedProviderId}
                                                selectedGameId={selectedGameId}
                                                selectedOperatorId={selectedOperatorId}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
