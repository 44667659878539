import { IGame } from '../Services/Api/IReportApiService';
import { GameApiService } from '../Services/Api/GameApiService';

export const loadGameSuggestions =
    (provider_id?: number, renderLabel = (game: IGame) => game.name) =>
    (q: string, callback: (response: any) => {}) => {
        GameApiService.fetchAllGamesApi({ q, provider_id }).then((response: any) => {
            return callback(
                response.data.map((game: { name: string; image: string; id: number }) => {
                    return {
                        image: game.image,
                        label: renderLabel(game),
                        value: game.id,
                    };
                })
            );
        });
    };
