import { createSlice } from '@reduxjs/toolkit';
import { IDashboardState } from '../types';

export const initialState: IDashboardState = {
    positionUpdates: [],
    scores: [],
    isInProgress: {
        fetchDashboardDataForPositionUpdates: false,
        fetchDashboardDataForScores: false,
    },
    message: '',
    success: null,
};

const reportsSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchDashboardDataForPositionUpdatesSuccessAction(state, action) {
            state.positionUpdates = action.payload.data;
            state.isInProgress.fetchDashboardDataForPositionUpdates = false;
        },
        fetchDashboardDataForScoresAction(state, action) {
            state.scores = action.payload.data;
            state.isInProgress.fetchDashboardDataForScores = false;
        },
        resetReportsReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    fetchDashboardDataForPositionUpdatesSuccessAction,
    fetchDashboardDataForScoresAction,
    setDashboardInProgressAction,
} = reportsSlice.actions;

export default reportsSlice.reducer;
