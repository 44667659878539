import React, { useState } from 'react';
import { Row, Col, CardBody, Card, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';

import { PageTitle } from '../../Components/Common/PageTitle';
import classnames from 'classnames';
import { AccountDetailsTab } from './AccountDetailsTab';
import { PasswordTab } from './PasswordTab';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const pathIndexMap = {
    '/account/settings/details': '1',
    '/account/settings/password': '2',
};

export const Settings = () => {
    const history = useNavigate();
    const location = useLocation();
    document.title = 'Account Settings';
    // @ts-ignore
    const [topBorderJustifyTab, setTopBorderJustifyTab] = useState<string>(pathIndexMap[location.pathname]);
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col>
                        <div className="h-100">
                            <PageTitle title="Account Settings" />
                            <Row>
                                <Col lg={12}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4">
                                            <Nav
                                                tabs
                                                className="nav nav-tabs nav-border-top nav-border-top-primary mb-3"
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: 'pointer' }}
                                                        className={classnames({ active: topBorderJustifyTab === '1' })}
                                                        onClick={() => {
                                                            setTopBorderJustifyTab('1');
                                                            history('/account/settings/details');
                                                        }}
                                                    >
                                                        Account Details
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: 'pointer' }}
                                                        className={classnames({ active: topBorderJustifyTab === '2' })}
                                                        onClick={() => {
                                                            setTopBorderJustifyTab('2');
                                                            history('/account/settings/password');
                                                        }}
                                                    >
                                                        Password
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>

                                            <TabContent activeTab={topBorderJustifyTab} className="text-muted">
                                                <TabPane tabId="1" id="nav-border-top-home">
                                                    <AccountDetailsTab />
                                                </TabPane>

                                                <TabPane tabId="2" id="nav-border-top-profile">
                                                    <PasswordTab />
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    );
};
