import { JOB_STATUSES } from '../../../Services/Api/Job/IJobApiService';

export const JobStatuses = ({ statuses, id }: { statuses: string[]; id: number }) => {
    return (
        <div className="job-statuses">
            {statuses.map((status: string, index) => {
                return (
                    <span
                        key={`job-status-${index}-${id}`}
                        className={`job-status ${status === JOB_STATUSES.DONE ? 'bg-success' : 'bg-danger'}`}
                    >
                        <span className="invisible" />
                    </span>
                );
            })}
        </div>
    );
};
