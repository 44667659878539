import { IOperator } from '../IOperatorApiService';
import { IApiPaginationResponse } from '../../../slices/types';

export type IJob = {
    original_id: number;
    created_at: string;
    status: string;
    date: string;
    info_url: string;
    error_image: string;
    games_parsed: number;
    not_found_games: number;
    tries: number;
    prev_statuses: string[];
    operator: IOperator;
};

export type IJobStat = {
    date: string;
    done: number;
    failed: number;
    in_progress?: number;
};

export type IFetchAllJobsApiResponse = {
    code: number;
    data: {
        data: IJob[];
        pagination: IApiPaginationResponse;
    };
};

export enum JOB_STATUSES {
    ALL = 'all',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
    FAILED = 'failed',
}
