import React, { useEffect, useState } from 'react';
import { Alert, Badge, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolio } from '../../../slices/reports/thunk';
import { portfolioDataSelector } from '../../../selectors/reportsSelectors';
import { userRelatedDataCountsSelector } from '../../../selectors/accountSelectors';
import { Label } from '../../../Components/Common/Label';
import { getComparisonColor } from '../../../utils/GameUtils';
import { OperatorRowItem } from '../../../Components/Common/Table/OperatorRowItem';
import { MoreItemsIcon } from '../../../Layouts/SVG/InfoCard/MoreItemsIcon';
import { setPortfolioMoreGamesDialogDataAction } from '../../../slices/global/reducer';

interface PortfolioTableProps {
    selectedProviderId: number;
    selectedGameId?: number;
    selectedOperatorId?: number;
    dashboard?: boolean;
}

const defaultPopupState = {
    isOpen: false,
    position: {
        top: 0,
        left: 0,
    },
};
export const PortfolioTable = ({
    selectedProviderId,
    selectedGameId = 0,
    selectedOperatorId = 0,
    dashboard = false,
}: PortfolioTableProps) => {
    const dispatch: any = useDispatch();
    const [popupState, setPopupState] = useState(defaultPopupState);

    const gameCountOnRow = dashboard ? 1 : 5;

    const handleButtonClick = (e: any, index: number) => {
        const rect = e.target.getBoundingClientRect();
        dispatch(
            setPortfolioMoreGamesDialogDataAction({
                games: operators?.[index]?.missing.slice(gameCountOnRow),
                isOpen: !popupState.isOpen,
                position: {
                    top: rect.bottom + window.scrollY - 25,
                    left: rect.left + window.scrollX,
                },
            })
        );
    };

    const { operators, isInProgress } = useSelector(portfolioDataSelector);
    const relatedDataCounts = useSelector(userRelatedDataCountsSelector);

    useEffect(() => {
        dispatch(fetchPortfolio(selectedProviderId, selectedGameId, selectedOperatorId));
    }, [selectedProviderId, selectedGameId, selectedOperatorId, dispatch]);

    return (
        <React.Fragment>
            <div>
                <CardBody>
                    {!selectedOperatorId && !selectedProviderId && !selectedGameId ? (
                        <Alert color="primary" className="mb-0">
                            Please select a provider
                        </Alert>
                    ) : isInProgress ? (
                        <></>
                    ) : operators.length === 0 ? (
                        <Alert color="primary" className="mb-0">
                            No data found for selected filters
                        </Alert>
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-nowrap">
                                <thead className="table-light">
                                    <tr>
                                        <td>Operator</td>
                                        {!selectedGameId ? (
                                            <>
                                                <td>Missing Games</td>
                                                <td className="min-w">Missing Games Count</td>
                                            </>
                                        ) : (
                                            <td>State</td>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(dashboard ? operators.slice(0, 10) : operators).map(
                                        ({ operator, games, missing }, index) => {
                                            return (
                                                <tr key={`section-info-${operator.id}`}>
                                                    <td>
                                                        <OperatorRowItem operator={operator} />
                                                    </td>
                                                    {!selectedGameId ? (
                                                        <>
                                                            <td>
                                                                <div className="game-list-row">
                                                                    {Array.isArray(missing) ? (
                                                                        missing
                                                                            ?.slice(0, gameCountOnRow)
                                                                            .map((game, gameIndex) => {
                                                                                return (
                                                                                    <Label
                                                                                        key={`section-info-${operator.id}-game-${game.id}`}
                                                                                    >
                                                                                        {game.name}
                                                                                        {gameIndex ===
                                                                                            gameCountOnRow - 1 &&
                                                                                            missing.length >
                                                                                                gameCountOnRow && (
                                                                                                <span
                                                                                                    className="more-games-btn"
                                                                                                    onClick={(e) => {
                                                                                                        return handleButtonClick(
                                                                                                            e,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                    color="primary"
                                                                                                >
                                                                                                    +{' '}
                                                                                                    {missing.length -
                                                                                                        gameCountOnRow}
                                                                                                    <MoreItemsIcon />
                                                                                                </span>
                                                                                            )}
                                                                                    </Label>
                                                                                );
                                                                            })
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="text-start">
                                                                <span
                                                                    className={`actual-amount ${getComparisonColor(games.length, relatedDataCounts.games)}`}
                                                                >
                                                                    {relatedDataCounts.games - games.length}
                                                                </span>
                                                                /{relatedDataCounts.games}
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <td>
                                                            {games.length ? (
                                                                <Badge color="success">Exists</Badge>
                                                            ) : (
                                                                <Badge color="warning">Missing</Badge>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </CardBody>
            </div>
        </React.Fragment>
    );
};
