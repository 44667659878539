import am from '../assets/images/flags/am.svg';
import br from '../assets/images/flags/br.svg';
import ar from '../assets/images/flags/ar.svg';
import fr from '../assets/images/flags/fr.svg';
import be from '../assets/images/flags/be.svg';
import bg from '../assets/images/flags/bg.svg';
import uk from '../assets/images/flags/gb.svg';
import us from '../assets/images/flags/us.svg';
import it from '../assets/images/flags/it.svg';
import gr from '../assets/images/flags/gr.svg';
import se from '../assets/images/flags/se.svg';

const flags = {
    am: {
        label: 'Armenia',
        flag: am,
    },
    br: {
        label: 'Brazil',
        flag: br,
    },
    ar: {
        label: 'Argentina',
        flag: ar,
    },
    fr: {
        label: 'France',
        flag: fr,
    },
    be: {
        label: 'Belgium',
        flag: be,
    },
    bg: {
        label: 'Bulgaria',
        flag: bg,
    },
    uk: {
        label: 'United Kingdom',
        flag: uk,
    },
    us: {
        label: 'USA',
        flag: us,
    },
    it: {
        label: 'Italy',
        flag: it,
    },
    gr: {
        label: 'Greece',
        flag: gr,
    },
    se: {
        label: 'Sweden',
        flag: se,
    },
};

export default flags;
