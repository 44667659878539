import { IGame, IReportGamesOnOperatorSectionRow } from '../Services/Api/IReportApiService';

export interface ITreeNode {
    index?: number;
    id?: number;
    name?: string;
    screenshot?: string;
    game_count?: number;
    games: IGame[];
    children: Record<string, ITreeNode>;
}

export function buildTree(sections: IReportGamesOnOperatorSectionRow[]): ITreeNode {
    const root: ITreeNode = {
        games: [],
        children: {},
    };

    sections.forEach(({ section, games }, sectionIndex) => {
        if (section) {
            const path = section.name.split(' | ');
            let currentNode = root;

            path.forEach((part) => {
                if (!currentNode.children[part]) {
                    currentNode.children[part] = {
                        index: sectionIndex,
                        name: part,
                        game_count: section.game_count,
                        screenshot: section.screenshot,
                        games: [],
                        children: {},
                    };
                }
                currentNode = currentNode.children[part];
            });

            // Attach games to the deepest node
            currentNode.games.push(...games);
        }
    });

    return root;
}
