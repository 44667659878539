import { IReportScoresOperatorRow } from '../Services/Api/IReportApiService';

export interface IReportScoresToChartData {
    labels: string[];
    values: number[];
}

export function transformScoresToChartData(data: IReportScoresOperatorRow, limit: number): IReportScoresToChartData {
    const labels: string[] = [];
    const values: number[] = [];
    let sumOfTheGames = 0;

    data.games.slice(0, limit).forEach((game): void => {
        labels.push(game.name);
        values.push(game.score!);
        sumOfTheGames += game.score!;
    });

    labels.push('Others');
    values.push(data.operator.score! - sumOfTheGames);

    return {
        labels,
        values,
    };
}
