import React from 'react';

import './assets/scss/themes.scss';

import Route from './Routes';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Route />
        </QueryClientProvider>
    );
}

export default App;
