import React, { useEffect } from 'react';
import { Row, Col, Input, Label, Form, FormFeedback, Button, Spinner } from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux';
import { useProfile } from '../../Components/Hooks/UserHooks';
import { updateAccountDetails } from '../../slices/auth/account/thunk';
import { accountDetailsDataSelector } from '../../selectors/accountSelectors';
import { resetAccountReducerStatus } from '../../slices/auth/account/reducer';

export const AccountDetailsTab = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();

    const info = useFormik({
        enableReinitialize: true,

        initialValues: {
            user_email: user.email,
            user_fname: user.fname,
            user_lname: user.lname,
        },
        validationSchema: Yup.object({
            user_email: Yup.string().required('Please Enter Your New Email'),
            user_fname: Yup.string().required('Please Enter Your First Name'),
            user_lname: Yup.string().required('Please Enter Your Last Name'),
        }),
        onSubmit: (values) => {
            dispatch(updateAccountDetails(values));
        },
    });
    const { isInProgress, message, success } = useSelector(accountDetailsDataSelector);

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetAccountReducerStatus());
                    },
                });
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetAccountReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                info.handleSubmit();
                return false;
            }}
            className="needs-validation"
        >
            <Row>
                <Col xs={12} sm={4}>
                    <div className="mb-3">
                        <Label htmlFor="useremail" className="form-label">
                            Email <span className="text-danger">*</span>
                        </Label>
                        <Input
                            id="email"
                            name="user_email"
                            className="form-control"
                            placeholder="Enter Email"
                            type="email"
                            onChange={info.handleChange}
                            onBlur={info.handleBlur}
                            value={info.values.user_email || ''}
                            invalid={info.touched.user_email && info.errors.user_email ? true : false}
                        />
                        {info.touched.user_email && info.errors.user_email ? (
                            <FormFeedback type="invalid">
                                <div>{info.errors.user_email as string}</div>
                            </FormFeedback>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="username" className="form-label">
                            First Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                            name="user_fname"
                            type="text"
                            placeholder="Enter First Name"
                            onChange={info.handleChange}
                            onBlur={info.handleBlur}
                            value={info.values.user_fname || ''}
                            invalid={info.touched.user_fname && info.errors.user_fname ? true : false}
                        />
                        {info.touched.user_fname && info.errors.user_fname ? (
                            <FormFeedback type="invalid">
                                <div>{info.errors.user_fname as string}</div>
                            </FormFeedback>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="username" className="form-label">
                            Last Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                            name="user_lname"
                            type="text"
                            placeholder="Enter Last Name"
                            onChange={info.handleChange}
                            onBlur={info.handleBlur}
                            value={info.values.user_lname || ''}
                            invalid={info.touched.user_lname && info.errors.user_lname ? true : false}
                        />
                        {info.touched.user_lname && info.errors.user_lname ? (
                            <FormFeedback type="invalid">
                                <div>{info.errors.user_lname as string}</div>
                            </FormFeedback>
                        ) : null}
                    </div>
                    <div className="mt-4">
                        <Button color="success" type="submit" disabled={isInProgress}>
                            {isInProgress && (
                                <Spinner size="sm" className="me-2">
                                    {' '}
                                    Loading...{' '}
                                </Spinner>
                            )}
                            Save
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
