import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { userRelatedDataCountsSelector } from '../../../selectors/accountSelectors';
import { InfoCard } from '../../../Components/Common/InfoCard';
import { fetchDashboardDataForPositionChange, fetchDashboardDataForScores } from '../../../slices/dashboard/thunk';
import { dashboardPositionUpdatesSelector, dashboardScoresSelector } from '../../../selectors/dashboardSelectors';
import { getOperatorsCountWithPositiveChangeForDashboard } from '../../../utils/GameUtils';
import { Link } from 'react-router-dom';
import { OperatorRowItem } from '../../../Components/Common/Table/OperatorRowItem';
import { PortfolioTable } from '../../Reports/Portfolio/PortfolioTable';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { GameRowItem } from '../../../Components/Common/Table/GameRowItem';

export const Dashboard = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const relatedDataCounts = useSelector(userRelatedDataCountsSelector);
    const { scores } = useSelector(dashboardScoresSelector);
    const { positionUpdates } = useSelector(dashboardPositionUpdatesSelector);
    const { operatorPositiveChangeCount, operatorNegativeChangeCount } =
        getOperatorsCountWithPositiveChangeForDashboard(positionUpdates);

    useEffect(() => {
        dispatch(fetchDashboardDataForPositionChange());
        dispatch(fetchDashboardDataForScores());
    }, []);
    document.title = 'Dashboard';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <PageTitle title={'Welcome'} />
                            <Row>
                                <Col md={6} sm={12}>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <InfoCard
                                                title="Total Tracked Games"
                                                iconType="game"
                                                amount={relatedDataCounts.games}
                                            />
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <InfoCard
                                                title="Total Tracked Operators"
                                                iconType="operator"
                                                iconColor="warning"
                                                amount={relatedDataCounts.operators}
                                            />
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <InfoCard
                                                description="Total count of gained positions"
                                                iconType="game"
                                                iconColor="success"
                                                amount={operatorPositiveChangeCount}
                                                amountColor={operatorPositiveChangeCount > 0 ? 'success' : 'warning'}
                                                amountType={operatorPositiveChangeCount > 0 ? 'up' : ''}
                                            />
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <InfoCard
                                                description="Total count of lost positions"
                                                iconType="game"
                                                iconColor="danger"
                                                amount={operatorNegativeChangeCount}
                                                amountColor={operatorNegativeChangeCount > 0 ? 'danger' : 'success'}
                                                amountType={operatorNegativeChangeCount > 0 ? 'down' : ''}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Card>
                                        <CardHeader className="display-flex justify-content-between align-items-center">
                                            <strong>Top positioned games by discovery score</strong>
                                            <Link to="/reports/scores">See more</Link>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="table-responsive">
                                                <table className="table table-nowrap">
                                                    <tbody>
                                                        {scores.slice(0, 3).map((game) => {
                                                            return (
                                                                <tr key={`scores-game-${game.id}`}>
                                                                    <td>
                                                                        <GameRowItem game={game} />
                                                                    </td>
                                                                    <td className="text-end bold">{game.score}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Card>
                                        <CardHeader className="display-flex justify-content-between align-items-center">
                                            <strong>Missing Games</strong>
                                            <Link to="/reports/missing-games">See more</Link>
                                        </CardHeader>
                                        <PortfolioTable selectedProviderId={user.providerId} dashboard={true} />
                                    </Card>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Card>
                                        <CardHeader className="display-flex justify-content-between align-items-center">
                                            <strong>Position Updates</strong>
                                            <Link to="/reports/position-updates">See more</Link>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="table-responsive">
                                                <table className="table table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <td>Operator</td>
                                                            <td>Games Found</td>
                                                            <td className="min-w">Position Changes Count</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {positionUpdates.slice(0, 10).map((row) => {
                                                            return (
                                                                <tr key={`pu-operator-${row.operator.id}`}>
                                                                    <td>
                                                                        <OperatorRowItem operator={row.operator} />
                                                                    </td>
                                                                    <td className="highlighted">
                                                                        {row.operator.game_count}
                                                                    </td>
                                                                    <td className="bold warning">{row.update.total}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
