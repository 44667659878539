import { IFetchAllGamesApiRequestBody } from 'slices/types';
import { GameApiService } from '../Services/Api/GameApiService';

export const loadClinetGameSuggestions =
    () => (params: IFetchAllGamesApiRequestBody, callback: (response: any) => void) => {
        GameApiService.fetchAllGamesApi(params).then((response: any) => {
            return callback(
                response.data.map((item: { name: string; id: number }) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                })
            );
        });
    };
