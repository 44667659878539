import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { allOperatorsDataSelector } from '../../../selectors/operatorsSelectors';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/Pagination';
import { Search } from 'Components/Common/Search';
import { fetchAllOperators } from 'slices/operators/thunk';
import { OperatorDialog } from './OperatorDialog';
import { get } from 'lodash';
import flags from '../../../common/flags';
import { IOperator } from '../../../Services/Api/IOperatorApiService';
import { PageTitle } from '../../../Components/Common/PageTitle';

export const AllOperators = () => {
    const dispatch: any = useDispatch();

    const { data, totalPages } = useSelector(allOperatorsDataSelector);
    const [isCreateNewOperatorDialogOpen, setIsCreateNewOperatorDialogOpen] = useState<boolean>(false);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedOperator, setSelectedOperator] = useState<IOperator>();

    const loadData = () => {
        dispatch(
            fetchAllOperators({
                page: selectedPage,
                q: searchTerm,
                page_size: 10,
            })
        );
    };

    useEffect(loadData, [selectedPage, searchTerm, dispatch]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title="All Operators" />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Search
                                                            search={(v) => setSearchTerm(v)}
                                                            placeholder="Search By Operator Name..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6} className="display-flex justify-content-end">
                                                        <Button
                                                            onClick={() => setIsCreateNewOperatorDialogOpen(true)}
                                                            className="c-btn"
                                                        >
                                                            Add new operator
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <td scope="col" className="min-w">
                                                                        ID
                                                                    </td>
                                                                    <td scope="col">Name</td>
                                                                    <td scope="col">Server Location</td>
                                                                    <td scope="col">Scraper Type</td>
                                                                    <td scope="col">Proxy Needed</td>
                                                                    <td scope="col">Status</td>
                                                                    <td scope="col" className="text-end min-w" />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((operator: any, index: number) => (
                                                                    <tr key={`operator-${operator.id}`}>
                                                                        <td>{operator?.id}</td>
                                                                        <td>{operator?.name}</td>
                                                                        <td>
                                                                            <img
                                                                                src={get(
                                                                                    flags,
                                                                                    `${operator.visible_in}.flag`
                                                                                )}
                                                                                alt={operator.visible_in}
                                                                                className="me-2 rounded"
                                                                                height="18"
                                                                            />
                                                                        </td>
                                                                        <td>{operator.scraper_type}</td>
                                                                        <td>{operator.proxy_needed ? 'Yes' : 'No'}</td>
                                                                        <td>
                                                                            {operator.status === 'active' ? (
                                                                                <Badge color="success">Active</Badge>
                                                                            ) : (
                                                                                <Badge color="warning">Inactive</Badge>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                color="info"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                    setSelectedOperator(operator);
                                                                                    setIsCreateNewOperatorDialogOpen(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        currentPage={selectedPage}
                        totalPages={totalPages}
                        changePage={(page) => setSelectedPage(page)}
                    />
                </Container>
            </div>
            <OperatorDialog
                isOpen={isCreateNewOperatorDialogOpen}
                onClose={(refresh = false) => {
                    setIsCreateNewOperatorDialogOpen(false);
                    if (refresh) {
                        loadData();
                    }
                }}
                operator={selectedOperator}
            />
            <ToastContainer />
        </>
    );
};
