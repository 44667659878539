export type IOperator = {
    id: number;
    name: string;
    slug?: string;
    status: OperatorStatuses;
    last_succeed_job_id: number;
    visible_in?: string;
    scraper_type?: OperatorScraperTypes;
    proxy_needed?: number;
};

export enum VisibleInOptions {
    ALL = 'all',
    AM = 'am',
    BE = 'be',
    BG = 'bg',
    BR = 'br',
    AR = 'ar',
    FR = 'fr',
    UK = 'uk',
    US = 'us',
    IT = 'it',
    GR = 'gr',
    SE = 'se',
}

export enum OperatorStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum OperatorScraperTypes {
    WDIO = 'wdio',
    PPTR = 'pptr',
}
