import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotFoundGames, toggleSkipGame } from '../../../slices/games/thunk';
import { notFoundGamesDataSelector } from '../../../selectors/gamesSelectors';
import { timeAgo } from '../../../utils/timeAgo';
import { MatchGamesDialog } from './MatchGamesDialog';
import { ToastContainer } from 'react-toastify';
import { Search } from '../../../Components/Common/Search';
import { DropDown } from '../../../Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';
import { Pagination } from '../../../Components/Common/Pagination';
import { loadOperatorSuggestions } from '../../../helpers/loadOperatorSuggestions';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { PlusIcon } from '../../../Layouts/SVG/InfoCard/PlusIcon';
import { AttachProviderByURLsDialog } from './AttachProviderByURLsDialog';
import { Switch } from '../../../Components/Common/Form/Switch';
import { SelectNFGSkipFilter } from '../../../Components/Selects/SelectNFGSkipFilter';
import { SkipFilterOptions } from '../../../Components/Selects/SkipFilterOptions';

export const NotFoundGames = () => {
    const dispatch: any = useDispatch();
    const [isMatchGamesDialogOpen, setIsMatchGamesDialogOpen] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<any>(null);
    const { data, isInProgress, totalPages } = useSelector(notFoundGamesDataSelector);

    const [selectedProviderId, setSelectedProviderId] = useState<number>(0);
    const [skipFilter, setSkipFilter] = useState<SkipFilterOptions>(SkipFilterOptions.ACTIVE);
    const [selectedOperatorIds, setSelectedOperatorIds] = useState<number[]>([]);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isAttachProviderByURLsDialogOpen, setIsAttachProviderByURLsDialogOpen] = useState<boolean>(false);

    const loadData = (): void => {
        dispatch(
            fetchNotFoundGames({
                page: selectedPage,
                q: searchTerm,
                provider_id: selectedProviderId,
                operator_ids: selectedOperatorIds,
                skip: skipFilter,
            })
        );
    };

    useEffect(() => {
        loadData();
    }, [selectedPage, searchTerm, selectedProviderId, selectedOperatorIds, skipFilter]);

    const openMatchGamesDialog = (game: any): void => {
        setIsMatchGamesDialogOpen(true);
        setSelectedGame(game);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle
                                    title={'Not Found Games'}
                                    buttons={[
                                        <Button
                                            onClick={() => setIsAttachProviderByURLsDialogOpen(true)}
                                            color="primary"
                                            className="c-btn"
                                        >
                                            <PlusIcon /> Attach provider by Image URLs
                                        </Button>,
                                    ]}
                                />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={3}>
                                                        <Search
                                                            onlyOnEnter={true}
                                                            search={setSearchTerm}
                                                            placeholder="Search By Game Name..."
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={3}>
                                                        <SelectNFGSkipFilter
                                                            setValue={setSkipFilter}
                                                            defaultValue={SkipFilterOptions.ACTIVE}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={3}>
                                                        <DropDown
                                                            handleSelect={setSelectedProviderId}
                                                            isInProgress={false}
                                                            loadSuggestions={() => loadProviderSuggestions()}
                                                            placeholder="Filter By Provider..."
                                                            hideImage={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={3}>
                                                        <DropDown
                                                            handleSelect={(id) => {
                                                                setSelectedPage(1);
                                                                console.log(id);
                                                                setSelectedOperatorIds(id as any as number[]);
                                                            }}
                                                            isMulti={true}
                                                            isInProgress={false}
                                                            loadSuggestions={() => loadOperatorSuggestions()}
                                                            placeholder="Filter By Operators..."
                                                            hideImage={true}
                                                            value={selectedOperatorIds}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <td scope="col">ID</td>
                                                                <td scope="col" colSpan={2}>
                                                                    Name
                                                                </td>
                                                                <td scope="col">Section</td>
                                                                <td scope="col">Provider</td>
                                                                <td scope="col">Operator</td>
                                                                <td scope="col">Date</td>
                                                                <td scope="col" style={{ width: '150px' }}>
                                                                    Action
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((game: any, index: number) => (
                                                                <tr key={`not-found-game-${game.nfg_id}`}>
                                                                    <td>{game.nfg_id}</td>
                                                                    <td>
                                                                        {game.nfg_name || (
                                                                            <Badge color="warning">No Name</Badge>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            className="img-thumbnail"
                                                                            width={200}
                                                                            src={game.nfg_image}
                                                                        />
                                                                    </td>
                                                                    <td>{game.section_name}</td>
                                                                    <td>
                                                                        {game?.provider_name}{' '}
                                                                        {!game?.provider_id && game?.provider_name && (
                                                                            <>
                                                                                {' '}
                                                                                -{' '}
                                                                                <Badge color="danger">Not Found</Badge>
                                                                            </>
                                                                        )}
                                                                        {!game?.provider_id && !game?.provider_name && (
                                                                            <Badge color="warning">No Provider</Badge>
                                                                        )}
                                                                    </td>
                                                                    <td>{game?.operator_name}</td>
                                                                    <td>{timeAgo(game.nfg_created_at)}</td>
                                                                    <td className="text-end">
                                                                        <Button
                                                                            color="primary"
                                                                            className="mb-2 c-btn"
                                                                            onClick={() => openMatchGamesDialog(game)}
                                                                        >
                                                                            Match
                                                                        </Button>
                                                                        <Switch
                                                                            defaultValue={game.nfg_skip === 1}
                                                                            onChange={(value) => {
                                                                                dispatch(
                                                                                    toggleSkipGame({
                                                                                        id: game.nfg_id,
                                                                                        value,
                                                                                    })
                                                                                );
                                                                            }}
                                                                            id={`toggle-skip-${game.nfg_id}`}
                                                                            label="Skip"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination currentPage={selectedPage} totalPages={totalPages} changePage={setSelectedPage} />
                </Container>
            </div>
            <MatchGamesDialog
                isOpen={isMatchGamesDialogOpen}
                game={selectedGame}
                onClose={(refresh = false) => {
                    setIsMatchGamesDialogOpen(false);
                    setSelectedGame(null);

                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <AttachProviderByURLsDialog
                isOpen={isAttachProviderByURLsDialogOpen}
                onClose={(refresh = false) => {
                    setIsAttachProviderByURLsDialogOpen(false);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <ToastContainer />
        </React.Fragment>
    );
};
