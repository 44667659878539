import React from 'react';
import Select from 'react-select';
import { JOB_STATUSES } from '../../Services/Api/Job/IJobApiService';
import { capitalize } from '../../utils/capitalize';

type SelectJobStatusProps = {
    setValue: (status: JOB_STATUSES) => void;
    placeholder: string;
};

const options = [
    { value: JOB_STATUSES.ALL, label: capitalize(JOB_STATUSES.ALL) },
    { value: JOB_STATUSES.IN_PROGRESS, label: capitalize(JOB_STATUSES.IN_PROGRESS) },
    { value: JOB_STATUSES.DONE, label: capitalize(JOB_STATUSES.DONE) },
    { value: JOB_STATUSES.FAILED, label: capitalize(JOB_STATUSES.FAILED) },
];

export const SelectJobStatus = ({ setValue, placeholder = 'Filter By Status...' }: SelectJobStatusProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            placeholder={placeholder}
            options={options}
            onChange={(e: { value: JOB_STATUSES; label: string }) => {
                setValue(e.value);
            }}
        />
    );
};
