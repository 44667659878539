import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DropDown } from '../../../Components/Common/DropDown';
import { IGame } from '../../../Services/Api/IReportApiService';
import { resetGamesReducerStatus } from '../../../slices/games/reducer';
import { loadGameSuggestions } from '../../../helpers/loadGameSuggestions';
import { mergeGames } from '../../../slices/games/thunk';
import { mergeGamesDataSelector } from '../../../selectors/gamesSelectors';

export const MergeGamesDialog = ({
    isOpen,
    onClose,
    childGame,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    childGame?: IGame;
}) => {
    const dispatch: any = useDispatch();
    const [parentGame, setParentGame] = useState<IGame | undefined>(undefined);

    const onMergeGames = (): void => {
        if (parentGame && childGame) {
            dispatch(mergeGames({ child_id: childGame?.id, parent_id: parentGame?.id }));
        }
    };

    const { message, success, isInProgress } = useSelector(mergeGamesDataSelector);

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetGamesReducerStatus);
                    },
                });

                onClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetGamesReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && onClose();
                }}
            >
                Merge Games
            </ModalHeader>
            <ModalBody className="text-center">
                {parentGame ? (
                    <Alert color="primary">
                        <strong>{childGame?.name}</strong> game will be removed and all its stats will be moved under{' '}
                        <strong>{parentGame?.name}</strong> game.
                    </Alert>
                ) : (
                    <Alert color="primary">
                        Please select a game under which <strong>{childGame?.name}</strong> game will be moved with its
                        stats.
                    </Alert>
                )}
                <div className="mt-4">
                    <DropDown
                        placeholder={'Search for a parent Game'}
                        handleSelect={(_, selectedOption) => {
                            setParentGame(
                                selectedOption
                                    ? ({
                                          id: selectedOption.value,
                                          name: selectedOption.label,
                                      } as IGame)
                                    : undefined
                            );
                        }}
                        isInProgress={isInProgress}
                        loadSuggestions={() =>
                            loadGameSuggestions(undefined, (game) => `${game.name} - ${game.provider?.name}`)
                        }
                        imageSize={100}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => onClose()} disabled={isInProgress}>
                    Close
                </Button>
                <Button color="primary" onClick={onMergeGames} disabled={isInProgress || !parentGame}>
                    {isInProgress ? (
                        <span className="d-flex align-items-center">
                            <Spinner className="top-25 start-50" animation="border" color="light" />
                            <span className="flex-grow-1 ms-2">Merging...</span>
                        </span>
                    ) : (
                        'Merge Games'
                    )}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
