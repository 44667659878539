import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { IReportScoresToChartData } from '../../../utils/transformScoresToChartData';
import { COLORS } from '../../../common/data/colors';

export const ScoresChart = ({ data }: { data: IReportScoresToChartData }) => {
    const options: any = {
        chart: {
            type: 'donut',
        },
        labels: data.labels,
        legend: {
            show: false,
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70%',
                },
            },
        },
        colors: COLORS,
    };

    return (
        <ReactApexChart
            dir="ltr"
            className="apex-charts"
            id="scores-per-game-in-operator"
            series={data.values}
            options={options}
            type="donut"
            width={400}
        />
    );
};
