import { createSelector } from 'reselect';
import { IDashboardState } from '../slices/types';

export const dashboardStateSelector = (state: any): IDashboardState => state.Dashboard;

export const dashboardPositionUpdatesSelector = createSelector(dashboardStateSelector, (reports) => ({
    positionUpdates: reports.positionUpdates,
    isInProgress: reports.isInProgress.fetchDashboardDataForPositionUpdates,
}));

export const dashboardScoresSelector = createSelector(dashboardStateSelector, (reports) => ({
    scores: reports.scores,
    isInProgress: reports.isInProgress.fetchDashboardDataForScores,
}));
