export const COLORS = [
    '#9B59B6',
    '#2E93fA',
    '#66DA26',
    '#E91E63',
    '#FF9800',
    '#34495E',
    '#2ECC71',
    '#546E7A',
    '#3498DB',
    '#16A085',
];
