import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/Pagination';
import { Search } from 'Components/Common/Search';
import { IFetchSelectedOperatorsApiRequestBody } from 'slices/types';
import { CreateNewOperatorDialog } from './OperatorDialog';
import { fetchSelectedOperators } from 'slices/client-operators/thunk';
import { selectedOperatorsDataSelector } from 'selectors/SelectedOperatorsSelectors';
import { DeleteOperatorDialog } from './DeleteOperatorDialog';
import { useProfile } from 'Components/Hooks/UserHooks';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { PlusIcon } from '../../../Layouts/SVG/InfoCard/PlusIcon';
import { Label } from '../../../Components/Common/Label';

export const Operators = () => {
    document.title = 'Operators';
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const [isCreateNewOperatorDialogOpen, setIsCreateNewOperatorDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isFromedit, setIsFromEdit] = useState<boolean>(false);
    const [selectedOperator, setSelectedOperator] = useState<any>(null);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [selectedProviderId, setSelectedProviderId] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { data, isInProgress, totalPages } = useSelector(selectedOperatorsDataSelector);
    const [params, setParams] = useState<IFetchSelectedOperatorsApiRequestBody>({
        page: 1,
        q: '',
        provider_id: 0,
        page_size: 10,
    });

    const handleSelectProvider = (providerId: any) => {
        setSelectedProviderId(providerId);
        setSelectedPage(1);
        setParams({ page: 1, q: searchTerm, provider_id: providerId });
    };

    const search = (query: string) => {
        setSearchTerm(query);
        setSelectedPage(1);
        setParams({ ...params, page: 1, q: query });
    };

    const openEditDialog = (operator: any): void => {
        setIsFromEdit(true);
        setIsCreateNewOperatorDialogOpen(true);
        setSelectedOperator(operator);
    };
    const openDeleteDialog = (operator: any): void => {
        setDeleteDialogOpen(true);
        setSelectedOperator(operator);
    };
    const loadData = () => {
        dispatch(fetchSelectedOperators(params));
    };

    useEffect(() => {
        loadData();
    }, [params]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle
                                    title={'Operators'}
                                    buttons={[
                                        <Button
                                            onClick={() => setIsCreateNewOperatorDialogOpen(true)}
                                            color="primary"
                                            className={`c-btn ${selectedProviderId || !user.isAdmin ? '' : 'disabled'}`}
                                        >
                                            <PlusIcon /> Add new operator
                                        </Button>,
                                    ]}
                                />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <Search
                                                            search={search}
                                                            placeholder="Search By Operator Name..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <td>#</td>
                                                                    <td>Name</td>
                                                                    <td>URL</td>
                                                                    <td className="text-center">Status</td>
                                                                    <td className="text-end">Actions</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((operator: any, index: number) => (
                                                                    <tr key={`operator-${operator.id}`}>
                                                                        <td>{(selectedPage - 1) * 10 + index + 1}</td>
                                                                        <td>{operator?.name}</td>
                                                                        <td>{operator?.url}</td>
                                                                        <td className="text-center">
                                                                            {operator.status === 'completed' && (
                                                                                <Label color="success">Completed</Label>
                                                                            )}
                                                                            {operator.status === 'pending' && (
                                                                                <Label color="gray">In Progress</Label>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openEditDialog(operator);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Edit
                                                                                </Button>{' '}
                                                                            </>
                                                                            <>
                                                                                {' '}
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openDeleteDialog(operator);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            </>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        currentPage={selectedPage}
                        totalPages={totalPages}
                        changePage={(page) => {
                            setSelectedPage(page);
                            setParams({ ...params, page });
                        }}
                    />
                </Container>
            </div>
            <CreateNewOperatorDialog
                isOpen={isCreateNewOperatorDialogOpen}
                operator={selectedOperator}
                providerId={selectedProviderId}
                isEdit={isFromedit}
                onClose={(refresh = false) => {
                    setIsCreateNewOperatorDialogOpen(false);
                    setSelectedOperator(null);
                    setIsFromEdit(false);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <DeleteOperatorDialog
                isOpen={isDeleteDialogOpen}
                operator={selectedOperator}
                onClose={(refresh = false) => {
                    setDeleteDialogOpen(false);
                    setSelectedOperator(null);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <ToastContainer />
        </>
    );
};
