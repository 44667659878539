import { BaseApiService } from '../BaseApiService';
import {
    IAccountDetailsUpdateApiRequestBody,
    IAccountDetailsUpdateDataResponse,
    IPasswordUpdateApiRequestBody,
    IUserRelatedDataResponse,
} from './IAccountApiService';

export class AccountApiService {
    static FETCH_USER_RELATED_DATA_URL = '/auth/related-data';
    static UPDATE_ACCOUNT_DETAILS_URL = '/auth/account-details';
    static UPDATE_PASSWORD_URL = '/auth/password';

    static fetchUserRelatedData(): Promise<IUserRelatedDataResponse> {
        return BaseApiService.get<IUserRelatedDataResponse>(AccountApiService.FETCH_USER_RELATED_DATA_URL);
    }

    static updateAccountDetails(
        params: IAccountDetailsUpdateApiRequestBody
    ): Promise<IAccountDetailsUpdateDataResponse> {
        return BaseApiService.put<IAccountDetailsUpdateDataResponse>(
            AccountApiService.UPDATE_ACCOUNT_DETAILS_URL,
            params
        );
    }

    static updatePassword(params: IPasswordUpdateApiRequestBody): Promise<IAccountDetailsUpdateDataResponse> {
        return BaseApiService.put<IAccountDetailsUpdateDataResponse>(
            AccountApiService.UPDATE_PASSWORD_URL,
            params
        );
    }
}
